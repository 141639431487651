<style scoped>
.v-input--is-disabled:not(.v-input--is-readonly) {
  background: #a6b3bb42;
}
</style>
<template>
  <v-dialog width="450" v-model="dialog.flag">
    <v-card>
      <v-card-title
        class="text-h6 primary white--text d-flex justify-space-between align-center"
      >
        <p v-if="dialog.call_tag">Edit Call Tag</p>
        <p v-else>Add Call Tag</p>
        <p>
          <v-icon large color="white" @click="dialog.flag = false">
            mdi-close
          </v-icon>
        </p>
      </v-card-title>

      <v-card-text v-if="skeletonLoader">
        <v-skeleton-loader
          class="elevation-0 pt-8 pb-0"
          v-bind="attrs"
          type="list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, actions"
        ></v-skeleton-loader>
      </v-card-text>

      <v-card-text v-else class="pb-0">
        <v-container class="px-0 pb-0" fluid>
          <v-radio-group v-model="callTag">
            <v-radio
              class="pb-2 black--text font-weight-bold"
              v-for="item in list_of_tags"
              :key="item.id"
              :label="item.call_tag"
              :value="item.call_tag"
            ></v-radio>
            <v-row>
              <v-col cols="1">
                <v-radio class="py-2" :value="customCallTag"> </v-radio>
              </v-col>
              <v-col cols="10" class="pb-0 pl-2">
                <v-text-field
                  outlined
                  dense
                  placeholder="Custom call tag"
                  v-model="customCallTag"
                  :disabled="disableCustomCallTag"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-radio-group>
        </v-container>
        <div class="d-flex justify-end">
          <v-btn
            class="white--text mt-0 mb-5 mr-2 secondary_2 px-6 rounded-pill"
            @click="postCallTag()"
          >
            <p v-if="dialog.call_tag">Edit</p>
            <p v-else>Add</p>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["dialog"],
  data() {
    return {
      skeletonLoader: true,
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2,
      },
      list_of_tags: [],
      disableCustomCallTag: true,
      addCallTagDialog: false,
      editCallTagDialog: false,
      editCallTagId: null,
      editCallTag: null,
      callTag: "",
      customCallTag: "",
      agent: "",
      bounceReason: "",
    };
  },
  watch: {
    // Disable and enable text field of custom call tag
    callTag(value) {
      this.disableCustomCallTag = true;
      let count = 0;
      for (let i = 0; i < this.list_of_tags.length; i++) {
        if (this.list_of_tags[i].call_tag == value) {
          count += 1;
        }
      }
      if (count == 0) {
        this.disableCustomCallTag = false;
      } else {
        this.disableCustomCallTag = true;
      }
    },
  },
  methods: {
    // to fetch call tag list
    getListOfTags() {
      const self = this;

      const successHandler = (response) => {
        if (response.data.success) {
          self.list_of_tags = response.data.tags[0];
          // console.log("tag list", self.list_of_tags);
          if (this.dialog.flag == true) {
            self.assignEditCallTagValues();
            this.skeletonLoader = false;
          }
        }
      };
      self.request_GET(
        self,
        self.$urls.CALL_TAG_LIST,
        null,
        successHandler,
        null,
        null,
        null
      );
      // console.log("call tag dialog", this.dialog);
    },

    // assign default values
    assignEditCallTagValues() {
      let count = 0;
      for (let i = 0; i < this.list_of_tags.length; i++) {
        if (this.list_of_tags[i].call_tag === this.dialog.call_tag) {
          count += 1;
        } else if (this.dialog.call_tag == "") {
          count += 1;
        }
      }
      if (count == 0) {
        // console.log("if",count)
        this.disableCustomCallTag = false;
        this.callTag = this.dialog.call_tag;
        this.customCallTag = this.dialog.call_tag;
      } else {
        // console.log("else",count)
        this.callTag = this.dialog.call_tag;
      }
    },

    // post call tag
    postCallTag() {
      if (this.callTag != "" || this.customCallTag != "") {
        const self = this;

        var form = new FormData();
        form.append("loan_id", this.dialog.loan_id);

        if (self.customCallTag != "" && self.disableCustomCallTag == false) {
          form.append("call_tag", self.customCallTag);
        } else {
          form.append("call_tag", self.callTag);
        }

        const successHandler = (response) => {
          console.log(response);
          if (response.data.success) {
            self.dialog.flag = false;
            this.$emit("success", {
                loan_id: this.dialog.loan_id,
                call_tag: response.data.call_tag,
              });
            self.disableCustomCallTag = true;
          }
        };
        self.request_POST(
          self,
          self.$urls.EDIT_CALL_TAG,
          form,
          successHandler,
          null,
          null,
          null
        );
      } else {
        this.showSnakeBar("error", "Select tag");
      }
    },
  },
  mounted() {
    this.getListOfTags();
  },
};
</script>
